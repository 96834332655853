import React from "react";
import FadeIn from "react-fade-in";
export default function About() {
  return (
    <div className="content">
      <FadeIn delay={200}>
        <h1 className="title">About</h1>
        <p>Content coming soon!</p>
      </FadeIn>
    </div>
  );
}
