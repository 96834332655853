import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <p>
          Made with{" "}
          <a
            className="text-link"
            href="https://react.dev/learn"
            target="_blank"
          >
            React
          </a>
        </p>
      </div>
    </div>
  );
}
