import "animate.css";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Home from "./pages/Home";
import FunStuff from "pages/FunStuff";

export default function App() {
  let location = useLocation();

  return (
    <div className="page-container">
      <Navbar />
      <div className="content-wrap">
        <Routes location={location}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/FunStuff" element={<FunStuff />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
